import React, { FC, useEffect } from 'react';
import { PageProps } from 'gatsby';
import { useDataLayerPush } from '@agria/theme/src/hooks/useDataLayerPush';

export interface BrochurewareDataLayerWrapperProps {
  children: any;
  data: PageProps['data'];
  serverData?: PageProps['serverData'];
  location?: PageProps['location'];
}

export const BrochurewareDataLayerWrapper: FC<
  React.PropsWithChildren<BrochurewareDataLayerWrapperProps>
> = ({ children, data, pageContext, serverData, location }) => {
  const { dataLayerPush } = useDataLayerPush();

  useEffect(() => {
    const { speciesName, topicName, id, ids } = pageContext;
    const { allSpecies = [] } = data?.page || {};
    const pageName = data?.page?.name;
    const guideName = data?.guideListingPage?.name;

    const pageMapping: {
      [key: string]: { contentType: string; petSpecies: string };
    } = {
      Agria: { contentType: 'Homepage', petSpecies: '' },
      'Dog Insurance': { contentType: 'Dog landing page', petSpecies: 'dog' },
      'Cat Insurance': { contentType: 'Cat landing page', petSpecies: 'cat' },
      'Rabbit Insurance': {
        contentType: 'Rabbit landing page',
        petSpecies: 'rabbit',
      },
      'Horse Owners': {
        contentType: 'Horse landing page',
        petSpecies: 'horse',
      },
      'Meet the equine team': {
        contentType: 'Horse content page',
        petSpecies: 'horse',
      },
      'How to Claim': { contentType: 'How to claim', petSpecies: '' },
      Contact: { contentType: 'Contact page', petSpecies: '' },
      'About us': { contentType: 'About page', petSpecies: '' },
      Careers: { contentType: 'Careers page', petSpecies: '' },
      'Guides and advice': {
        contentType: 'Guides landing page',
        petSpecies: '',
      },
      Rehoming: {
        contentType: 'Rehoming centres landing page',
        petSpecies: '',
      },
      Vets: { contentType: 'Vets landing page', petSpecies: '' },
      Breeders: { contentType: 'Breeders content', petSpecies: '' },
      Loyalty: { contentType: 'Loyalty page', petSpecies: '' },
    };

    let contentType = '';
    let petSpecies = '';

    if (pageMapping[pageName]) {
      ({ contentType, petSpecies } = pageMapping[pageName]);
    } else if (guideName === 'Guides and advice' && speciesName && !topicName) {
      contentType = 'Guides species page';
      petSpecies = speciesName.toLowerCase();
    } else if (allSpecies?.length > 0) {
      contentType = 'Guides topic page';
      petSpecies = allSpecies
        .map((species) => species.name)
        .join(', ')
        .toLowerCase();
    } else if (
      guideName === 'Guides and advice' &&
      speciesName &&
      ((id && id.includes('GuidesAndAdviceArticleType')) ||
        (ids[0] && ids[0].includes('GuidesAndAdviceArticleType')))
    ) {
      contentType = 'Guide content';
      petSpecies = speciesName.toLowerCase();
    }

    dataLayerPush({
      content_group: 'brochureware pages',
      content_type: contentType,
      pet_species: petSpecies,
      event: 'brochureware_pageview',
      spa_page_url: location?.href,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return children;
};
