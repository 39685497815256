/* eslint-disable react/no-danger */
import type { GatsbySSR } from 'gatsby';
import React from 'react';
import { GlobalLoaderProvider } from './src/context/GlobalLoaderContext';
import { MyAccountProvider } from './src/context/MyAccountContext';

export const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }) => (
  <MyAccountProvider>
    <GlobalLoaderProvider>{element}</GlobalLoaderProvider>
  </MyAccountProvider>
);

export const onRenderBody: GatsbySSR['onRenderBody'] = ({
  setHeadComponents,
}) => {
  setHeadComponents([
    <script
      key="partytown-vanilla-config"
      dangerouslySetInnerHTML={{
        __html: `partytown = { debug: false }`,
      }}
    />,
  ]);
};
