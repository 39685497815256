
  export const isProd =
(process.env.GATSBY_CLOUD || process.env.NETLIFY || process.env.GATSBY_NETLIFY) &&
  (process.env.BRANCH === 'main' || process.env.GATSBY_BRANCH === 'main' || process.env.BRANCH === 'ie-live-netlify' || process.env.GATSBY_BRANCH === 'ie-live-netlify');

  console.log('isProd', isProd);
  console.log(process.env.GATSBY_BRANCH, process.env.GATSBY_NETLIFY);
// TODO: We will use this bu while the other sites are in development ue above
// export const isProd =
//   (process.env.GATSBY_CLOUD && process.env.GATSBY_BRANCH === 'main') ||
//   process.env.GATSBY_USE_PROD_API === 'true';
