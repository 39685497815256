const layers = {
  heroBackgroundImage: -1,
  base: 0,
  heroBody: 1,
  headerSearch: 2,
  filterInput: 3,
  currencyInput: 4,
  datePicker: 5,
  stickyPrice: 999,
  modalOverlay: 99999,
} as const;

export default layers;

export type ThemeLayers = typeof layers;
