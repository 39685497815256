import { useMutation } from '@tanstack/react-query';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: MJS workaround to allow in JS file
import { service } from '../client.mjs';
import { dataOrError } from '../utils/dataOrError';
import { Account, ApiResponse } from '../types';

export interface AuthenticatePayload {
  AgriaId?: string;
  BrandId?: string;
  EmailAddress?: string;
  Password?: string;
  Postcode?: string;
}

const getAuthenticate = async (payload: AuthenticatePayload) => {
  const { data } = await service.post<ApiResponse<Account>>(
    `/Account/Authenticate`,
    payload
  );
  return dataOrError<Account>(data);
};

export function useAuthenticate() {
  return useMutation(getAuthenticate, {
    mutationKey: ['authenticate'],
  });
}
