import { siteLinks } from '@agria/theme/src/const/siteLinks';
import { isBrowser } from '@agria/utils';
import { telephoneNumbers } from '@agria/theme/src/const/telephoneNumbers';
import { myAccountLinks } from './myAccountLinks';
import { NavItem } from '../types/global';
import { ctaButtons } from './ctaButtons';

//
// CUSTOMERS
//

export const customerLinks: NavItem[] = [
  {
    label: 'Home',
    url: myAccountLinks.dashboard,
    ctaOverride: ctaButtons.customer,
  },
  {
    label: 'My pets',
    url: myAccountLinks.myPets,
    partiallyActive: true,
    subPageLabel: 'Back to My pets',
    subPageUrl: myAccountLinks.myPetsPolicy,
    ctaOverride: ctaButtons.customer,
  },
  {
    label: 'My claims',
    url: myAccountLinks.myClaims,
    ctaOverride: ctaButtons.customer,
  },
  {
    label: 'My rewards',
    url: myAccountLinks.myRewards,
    ctaOverride: ctaButtons.customer,
  },
  {
    label: 'Agria Vet Guide',
    url: myAccountLinks.vetGuide,
    ctaOverride: ctaButtons.customer,
  },
  {
    label: 'Inclusive benefits',
    url: myAccountLinks.inclusiveBenefits,
    ctaOverride: ctaButtons.customer,
  },
  {
    label: 'Refer a friend',
    url: myAccountLinks.referAFriend,
    ctaOverride: ctaButtons.customer,
  },
  {
    label: 'Account settings',
    url: myAccountLinks.accountSettings,
    ctaOverride: ctaButtons.customer,
  },
  {
    label: 'Help and contact',
    url: myAccountLinks.helpAndContact,
    ctaOverride: ctaButtons.customer,
  },
];

//
// VETS
//

const vetOverides = {
  ctaOverride: ctaButtons.vets,
  telephoneNumberOverride: telephoneNumbers.vetSupport,
};

export const vetLinks: NavItem[] = [
  {
    label: 'Home',
    url: myAccountLinks.dashboard,
    ...vetOverides,
  },
  {
    label: '5 weeks free cover',
    url: myAccountLinks.freeCover,
    ...vetOverides,
  },
  {
    label: 'Claims',
    url: myAccountLinks.claims,
    ...vetOverides,
  },
  {
    label: 'Your reward statement',
    url: myAccountLinks.rewardStatement,
    ...vetOverides,
  },
  {
    label: 'Marketing materials',
    url: myAccountLinks.marketingMaterials,
    ...vetOverides,
  },
  {
    label: 'Account settings',
    url: myAccountLinks.accountSettings,
    ...vetOverides,
  },
  {
    label: 'Help and contact',
    url: myAccountLinks.helpAndContact,
    ...vetOverides,
  },
];

export const nonAffiliateVetLinks: NavItem[] = [
  {
    label: 'Home',
    url: myAccountLinks.dashboard,
  },
  {
    label: 'Claims',
    url: myAccountLinks.claims,
  },
  {
    label: 'Your reward statement',
    url: myAccountLinks.rewardStatement,
  },
  {
    label: 'Account settings',
    url: myAccountLinks.accountSettings,
  },
  {
    label: 'Help and contact',
    url: myAccountLinks.helpAndContact,
  },
];

//
// BREEDERS
//

export const breederLinks: NavItem[] = [
  {
    label: 'Home',
    url: myAccountLinks.dashboard,
    ctaOverride: ctaButtons.breeder,
  },
  {
    label: '5 weeks free cover',
    url: myAccountLinks.freeCover,
    ctaOverride: ctaButtons.breeder,
  },
  {
    label: 'Add a litter',
    url: myAccountLinks.addALitter,
    ctaOverride: ctaButtons.breeder,
  },
  {
    label: 'Your reward statement',
    url: myAccountLinks.rewardStatement,
    ctaOverride: ctaButtons.breeder,
  },
  {
    label: 'Account settings',
    url: myAccountLinks.accountSettings,
    ctaOverride: ctaButtons.breeder,
  },
  {
    label: 'Help and contact',
    url: myAccountLinks.helpAndContact,
    ctaOverride: ctaButtons.breeder,
  },
];

//
// REHOMING (charities)
//

const rehomingOverides = {
  ctaOverride: ctaButtons.rehoming,
  telephoneNumberOverride: telephoneNumbers.rehomngSupport,
};

export const rehomingLinks: NavItem[] = [
  {
    label: 'Home',
    url: myAccountLinks.dashboard,
    ...rehomingOverides,
  },
  {
    label: '5 weeks free cover',
    url: myAccountLinks.freeCover,
    ...rehomingOverides,
  },
  {
    label: 'Your reward statement',
    url: myAccountLinks.rewardStatement,
    ...rehomingOverides,
  },
  {
    label: 'Marketing materials',
    url: myAccountLinks.marketingMaterials,
    ...rehomingOverides,
  },
  {
    label: 'Account settings',
    url: myAccountLinks.accountSettings,
    ...rehomingOverides,
  },
  {
    label: 'Help and contact',
    url: myAccountLinks.helpAndContact,
    ...rehomingOverides,
  },
];
