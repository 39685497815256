import {
  AuthenticatePayload,
  useAuthenticate,
} from '@agria/paws/src/hooks/useAuthenticate';
import { useAffiliateLookup } from '@agria/paws/src/hooks/useAffiliateLookup';
import { useGetCustomerDetails } from '@agria/paws/src/hooks/useGetCustomerDetails';
import { useGetAffiliateTypes } from '@agria/paws/src/hooks/useGetAffiliateTypes';
import { useCustomerUpdateFlag } from '@agria/paws/src/hooks/useCustomerUpdateFlag';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
  useCallback,
} from 'react';
import { Affiliate, CustomerDetails } from '@agria/paws/src/types';
import { navigate } from 'gatsby';
import { NavItem } from '../types/global';
import {
  breederLinks,
  customerLinks,
  nonAffiliateVetLinks,
  rehomingLinks,
  vetLinks,
} from '../const/navigationItems';

interface User {
  CustomerId: string;
  AffiliateId?: string;
  AgriaId?: string;
  SecurityRole?: number;
}

type AffiliateCategory =
  | 'Vet'
  | 'Non-affiliate Vet'
  | 'Breeder'
  | 'Rehoming'
  | 'Charity'
  | 'Agria Partner'
  | 'Brand Owner'
  | 'Agria Staff'
  | 'Event'
  | 'undefined';

interface AffiliateUser extends Affiliate {
  FullName: string;
  FirstName: string;
  AffiliateType: string;
  AffiliateCategory: AffiliateCategory | null;
}

interface CustomerUser extends CustomerDetails {
  FullName: string;
  FirstName: string;
}

interface MyAccountContextType {
  user: User | null;
  affiliate: AffiliateUser | null;
  customer: CustomerUser | null;
  login: (options: AuthenticatePayload) => void;
  loginError?: boolean;
  logout: () => void;
  isLoading: boolean;
  navItems: NavItem[];
}

// Use a type assertion instead of "undefined"
const MyAccountContext = createContext<MyAccountContextType | undefined>(
  undefined
);

export const useMyAccount = () => {
  const context = useContext(MyAccountContext);
  if (!context) {
    throw new Error('useMyAccount must be used within an MyAccountProvider');
  }
  return context;
};

interface MyAccountProviderProps {
  children: ReactNode;
}

interface Category {
  Id: string;
  Name: string;
}

const getAffiliateCategoryByTypeId = (
  id: string,
  AppointedRepresentativeStatusId: string,
  categoryArray: Category[]
): AffiliateCategory => {
  // Define an object to map similar IDs to their generic category
  const idToCategoryMap: Record<string, AffiliateCategory> = {
    '5424f58c-2c3a-4f02-a748-823cc5f2daa2': 'Vet', // Vet
    '5603fa8a-5fe7-47b9-a07c-efe11f8a5f7f': 'Vet', // Vet Partner
    '7e1f75d8-a9fe-4ba8-af78-4120c26a26c7': 'Vet', // Vet Sector Specialist
    '008e96b9-8d90-493d-9c3a-bc04d81c07ad': 'Vet', // Overseas Vet (included with Vet)
    'a04b49c9-e8ee-4477-a5bd-e40a9a446183': 'Breeder', // Breeder
    '466385a7-c330-404f-bf6a-305a751b5eb5': 'Breeder', // Breeder (Guest)
    '6eed0231-1786-4401-8237-c43dcf4930e4': 'Breeder', // Breeder agent
    '5e753897-384c-474a-a590-de2a70b9f146': 'Breeder', // Breeder Donation Charity
    '0021c8c7-4906-4951-bd17-496873c620ad': 'Charity', // Charity - Anilog
    'd28f01f3-a3b4-4ae9-8dc2-6fb91b59987c': 'Charity', // Charity - Direct
    '3f10dce8-f19b-47c9-ae36-18dcf982d166': 'Event', // Event
    '5590e894-2697-4b00-99f0-af79cee7835b': 'Agria Partner', // Agria Partner
    '5afa8104-d5d0-4a54-b458-7fdd6bdc9ae8': 'Agria Staff', // Agria Staff
    'f2c86dc5-604e-43a2-addb-bc45bd222777': 'Brand Owner', // Brand Owner
  };

  // Check if the given ID is in the map
  if (idToCategoryMap[id] !== undefined) {
    // Categorise vets further by affiliate/non-affiliate
    // 'C1E3ED2A-5C2B-4047-AA90-F24CD4FFB6B5' // Agria activated
    // '41422B49-CF35-4917-8329-18B68C818E6C' // Owner activated
    // '8DA68463-0134-4A4A-8412-870A022693B7' // vet activated
    if (idToCategoryMap[id] === 'Vet') {
      if (
        AppointedRepresentativeStatusId !==
          'c1e3ed2a-5c2b-4047-aa90-f24cd4ffb6b5' &&
        AppointedRepresentativeStatusId !==
          '41422b49-cf35-4917-8329-18b68c818e6c' &&
        AppointedRepresentativeStatusId !==
          '8da68463-0134-4a4a-8412-870a022693b7'
      ) {
        return 'Non-affiliate Vet';
      }

      return 'Vet';
    }
    return idToCategoryMap[id];
  }

  // If the ID is not directly in the map, check for similar IDs
  for (const item of categoryArray) {
    // Compare the first 8 characters of the ID (you can adjust this as needed)
    if (id.startsWith(item.Id.substring(0, 8))) {
      return item.Name as AffiliateCategory;
    }
  }

  // If no match is found, return a default category or null
  return 'undefined';
};

export const MyAccountProvider: React.FC<MyAccountProviderProps> = ({
  children,
}) => {
  const [navItems, setNavItems] = useState<NavItem[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [affiliate, setAffiliate] = useState<AffiliateUser | null>(null);
  const [customer, setCustomer] = useState<CustomerUser | null>(null);
  const { data: affiliateTypes } = useGetAffiliateTypes();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate: sendCustomerFlag } = useCustomerUpdateFlag();

  // Hooks to get data

  const {
    data: customerData,
    fetchCustomerDetails,
    isError: customerError,
    isLoading: customerIsLoading,
  } = useGetCustomerDetails();

  const {
    mutate: getAffiliateDetails,
    data: affiliateData,
    isError: affiliateError,
    isLoading: affiliateLoading,
  } = useAffiliateLookup();

  const {
    mutate: loginUser,
    data: loginData,
    isError: loginError,
    isLoading: loginLoading,
    isSuccess: loginSuccess,
  } = useAuthenticate();

  // Handle loading login states

  useEffect(() => {
    if (loginLoading || affiliateLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [affiliateLoading, loginLoading, customerIsLoading]);

  // Handle getting customer or partner details

  useEffect(() => {
    // Get user details from storage
    const storedUser = sessionStorage.getItem('user');
    if (storedUser) {
      const parsedUser: User = JSON.parse(storedUser);

      //   if (!parsedUser.AgriaId || !parsedUser.CustomerId) {
      //     alert('TODO - what shall we do here? global erro state?');
      //   }

      // Check if the user is a customer
      if (parsedUser.CustomerId) {
        // Get customer details
        fetchCustomerDetails(parsedUser.CustomerId);
      } else {
        // Get affiliate details
        getAffiliateDetails({ agriaId: parsedUser.AgriaId });
      }

      setUser(parsedUser);
    } else {
      // If no user then redirect to login - TODO not sure if this is right approach
      // navigate('/my-account/login');
    }
  }, []);

  // set customer

  const setCurrentCustomer = (data) => {
    // Add fullName property to CustomerDetails
    const customerWithFullName: CustomerUser = {
      ...data,
      FullName: `${data.FirstName} ${data.LastName}`,
      FirstName: `${data.FirstName}`,
    };

    // Set full customer details to state
    setCustomer(customerWithFullName);

    // Set nav items for customer to state
    setNavItems(customerLinks);
  };

  const setCurrentAffiliate = (data) => {
    if (affiliateTypes) {
      // Add fullName and affiliate type property to Affiliate
      const affiliateWithFullName: AffiliateUser = {
        ...data,
        FullName: `${data.Forename} ${data.Surname}`,
        FirstName: `${data.Forename}`,
        AffiliateType:
          affiliateTypes.find((type) => type.Id === data.AffiliateTypeId)
            ?.Name || 'undefined',
        AffiliateCategory: getAffiliateCategoryByTypeId(
          data.AffiliateTypeId,
          data.AppointedRepresentativeStatusId,
          affiliateTypes
        ),
      };

      // Set full affiliate details to state

      setAffiliate(affiliateWithFullName);

      // Set nav items based on affiliate category to state

      if (affiliateWithFullName.AffiliateCategory === 'Non-affiliate Vet') {
        setNavItems(nonAffiliateVetLinks);
      }

      if (affiliateWithFullName.AffiliateCategory === 'Vet') {
        setNavItems(vetLinks);
      }

      if (
        affiliateWithFullName?.AffiliateCategory === 'Charity' ||
        affiliateWithFullName?.AffiliateCategory === 'Rehoming'
      ) {
        setNavItems(rehomingLinks);
      }

      if (affiliateWithFullName?.AffiliateCategory === 'Breeder') {
        setNavItems(breederLinks);
      }
    }
  };

  // Handle fetching customer and affiliate data when we have user data

  useEffect(() => {
    if (user) {
      if (user.CustomerId) {
        // If your the same user trying to login just let them in...

        if (user.CustomerId === customerData?.Id) {
          setCurrentCustomer(customerData);
          navigate('/my-account');
        } else {
          fetchCustomerDetails(user.CustomerId);
        }
      } else if (user.AgriaId === affiliateData?.AgriaId) {
        setCurrentAffiliate(affiliateData);
        navigate('/my-account');
      } else {
        getAffiliateDetails({ agriaId: user.AgriaId });
      }
    }
  }, [user]);

  // Handle setting user details in state if they exist

  // Affiliate details

  useEffect(() => {
    if (affiliateData && !affiliateError && affiliateTypes) {
      setCurrentAffiliate(affiliateData);
    }
  }, [affiliateData, affiliateTypes, affiliateError]);

  // Customer details

  useEffect(() => {
    if (customerData && !customerError) {
      setCurrentCustomer(customerData);
    }
  }, [customerData, customerError]);

  // Handle login

  useEffect(() => {
    if (loginData && !loginError && loginSuccess && !loginLoading) {
      const userData: User = loginData;

      // Store user data in session storage
      sessionStorage.setItem('user', JSON.stringify(userData));
      setUser(userData);

      // Update flag when customer logs in

      if (userData.CustomerId) {
        sendCustomerFlag({
          customerId: userData.CustomerId,
          flagId: '11388D3A-0257-4A55-B8BC-C67B7B237077',
        });
      }
    }
  }, [loginData, loginError, loginSuccess, loginLoading, sendCustomerFlag]);

  // Handle login

  const login = useCallback(
    (options: AuthenticatePayload) => {
      loginUser(options);
    },
    [loginUser]
  );

  // Handle logout

  const logout = useCallback(() => {
    sessionStorage.removeItem('user');
    navigate('/');
    setCustomer(null);
    setAffiliate(null);
    setUser(null);
  }, []);

  // Set context value

  const contextValue = useMemo(
    () => ({
      user,
      affiliate,
      customer,
      login,
      loginError,
      logout,
      navItems,
      isLoading,
    }),
    [user, affiliate, customer, login, logout, navItems, isLoading, loginError]
  );

  return (
    <MyAccountContext.Provider value={contextValue}>
      {children}
    </MyAccountContext.Provider>
  );
};
