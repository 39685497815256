import React from 'react';
import { ThemeProvider } from '@emotion/react';
import theme from '@agria/theme/src/styles/tokens';
import { getColorTones } from '@agria/theme/src/utils';
import { SitewideMessage } from '@agria/theme/src/components/sitewideMessage/SitewideMessage';
// import { useSiteCountry } from '@agria/theme/src/hooks/useSiteCountry';
import Header from '../slices/Header';
import Footer from '../slices/Footer';

export const BaseLayout = ({
  brand = 'agria',
  children,
}: React.PropsWithChildren<{ brand?: 'agria' | 'BASC' | 'GCCF' }>) => {
  let newTheme = { ...theme };

  // const { isIe } = useSiteCountry();

  if (brand === 'BASC') {
    const bascFontFamily = 'Roboto Slab, serif';

    const bascTYpography = {
      fontFamily: bascFontFamily,
      fontWeight: 400,
    };

    const bascTYpographyBold = {
      fontFamily: bascFontFamily,
      fontWeight: 600,
    };

    newTheme = {
      ...newTheme,
      colors: {
        ...newTheme.colors,
        ...getColorTones('#078463', 'brandOne'),
        ...getColorTones('#014711', 'brandTwo'),
        utilityBackground: '#E6F3EF',
        utilityText: '#3C4858',
      },
      typography: {
        ...newTheme.typography,
        headingXLarge: {
          ...newTheme.typography.headingXLarge,
          ...bascTYpographyBold,
        },
        headingLarge: {
          ...newTheme.typography.headingLarge,
          ...bascTYpographyBold,
        },
        headingMedium: {
          ...newTheme.typography.headingMedium,
          ...bascTYpographyBold,
        },
        headingSmall: {
          ...newTheme.typography.headingSmall,
          ...bascTYpographyBold,
        },
        bodyLarge: {
          ...newTheme.typography.headingSmall,
          ...bascTYpography,
        },
        bodyLargeBold: {
          ...newTheme.typography.headingSmall,
          ...bascTYpographyBold,
        },
        quote: {
          ...newTheme.typography.headingSmall,
          ...bascTYpographyBold,
        },
      },
    };
  }

  if (brand === 'GCCF') {
    newTheme = {
      ...newTheme,
      colors: {
        ...newTheme.colors,
        ...getColorTones('#452683', 'brandOne'),
        ...getColorTones('#C29C5E', 'brandTwo'),
        utilityBackground: '#ECE9F3',
        utilityText: '#452683',
      },
    };
  }

  return (
    <ThemeProvider
      theme={brand === null || brand === 'agria' ? theme : newTheme}
    >
      {brand === 'BASC' && (
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;600&display=swap');
        </style>
      )}

      {/* <Slice alias="header" brand={brand} /> */}

      <Header brand={brand} />
      {/* {!isIe && ( */}
      <SitewideMessage
        message="Our online journeys will be down due to scheduled maintenance between 7am and 8am, we apologise for any inconveniences caused"
        startTime="2024-03-19T05:00:00"
        endTime="2024-02-19T08:00:00"
      />
      {/* )} */}
      <main>{children}</main>
      <Footer />
      {/* <Slice alias="footer" /> */}
    </ThemeProvider>
  );
};
