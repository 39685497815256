module.exports = [{
      plugin: require('../../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://29acb73edeef614335cd4bec4437a97d@o207017.ingest.sentry.io/4506110032740352"},
    },{
      plugin: require('../../../themes/paws/gatsby-browser.tsx'),
      options: {"plugins":[],"campaignId":"92d67c6a-0317-4313-a7c8-32a94786b18a"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"/opt/buildhome/repo/packages/themes/agria/src/images/icon.png","icons":[{"src":"/opt/buildhome/repo/packages/themes/agria/src/images/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/opt/buildhome/repo/packages/themes/agria/src/images/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"name":"Agria Pet Insurance","short_name":"Agria","start_url":"/","background_color":"#02315B","theme_color":"#02315B","display":"minimal-ui","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d6cc577f819156acde70f78cd627bcd6"},
    },{
      plugin: require('../../../themes/agria/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/my-account/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../themes/brochureware/gatsby-browser.tsx'),
      options: {"plugins":[],"companyName":"Agria Pet Insurance","contactNumber":"03330 30 10 00","footerTerms":"\n          <p>\n            For UK customers: <br />\n\n            Agria Pet Insurance Ltd is authorised and regulated by the Financial Conduct Authority, Financial Services Register Number 496160. Agria Pet Insurance Ltd is registered and incorporated in England and Wales with registered number 04258783. Registered office: First Floor, Blue Leanie, Walton Street, Aylesbury, Buckinghamshire, HP21 7QW. Agria insurance policies are underwritten by Agria Försäkring.\n          </p>\n          <p>\n            For Guernsey customers: <br />\n          \n            Clegg Gifford Channel Islands Limited is licensed by the Guernsey Financial Services Commission. Ref: 2722221. Registered office: Admiral House, Place Du Commerce, St Peter Port, Guernsey GY1 2AT.\n          </p>\n          ","trustpilotUrl":"https://www.trustpilot.com/review/agriapet.co.uk","isFreeJourney":true,"petOrAnimal":"pet","affiliateType":"breeder","allowedSpecies":["Cat","Dog","Rabbit","Equine"],"enableMyAccount":true,"graphqlUrl":"https://dev-agria.uksouth01.umbraco.io/graphql","siteId":1221,"extraInterfaces":[{"interfaceName":"MyAccountGraphTypeInterface","nodeName":"myAccount"}]},
    },{
      plugin: require('../../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
